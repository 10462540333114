import {
  useSearchBox
} from "react-instantsearch";

import { TextField } from "@mui/material";
import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import MenuIcon from '@mui/icons-material/Menu';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import { useInstantSearch } from 'react-instantsearch';
import { debounce } from '@mui/material/utils'




const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  // [theme.breakpoints.up('sm')]: {
  //   marginLeft: theme.spacing(1),
  //   width: 'auto',
  // },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));


export function SearchBox(props) {
  // const refresh = props.refresh;
    const {
      query,
      refine,
      currentRefinement,
      clear,
      // Deprecated
      isSearchStalled,
    } = useSearchBox(props);
    const { refresh } = useInstantSearch();
    return (
      <Search >

          <IconButton onClick={() => {
            refresh();
            document.querySelector("#bottom-scroll").scrollIntoView({ block: "start" });
          //   document
          // .querySelector("#scroller")
          // .scrollIntoView({ block: "start" });
          }} sx={{
  padding: 1,
  height: '100%',
  display: 'flex',
  color: 'white',
  zIndex: 999999,
  position: 'absolute',
  alignItems: 'center',
  justifyContent: 'center',
}}>
            <RefreshIcon />
            </IconButton>

            <StyledInputBase
            value={currentRefinement}
              onChange={(event) => {
                debounce((v) => {
                  refine(v)
                }, 700)(event.target.value)
                
                
          //       document
          // .querySelector("#scroller")
          // .scrollIntoView({ block: "start" });
              }}
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
            />

          </Search>
    )
    return (
      <>
        {
          <TextField
            size="small"
            fullWidth
            label="Enter search query"
            variant="outlined"
            onChange={(event) => refine(event.target.value)}
          />
        }
      </>
    );
  }
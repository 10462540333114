import * as React from 'react';
import { useMemo, useRef, useState ,useEffect} from "react";
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import { Hits } from "./Hits/Hits";
import { useSelector, useDispatch } from 'react-redux'

import { createInfiniteHitsSessionStorageCache } from 'instantsearch.js/es/lib/infiniteHitsCache';
const sessionStorageCache = createInfiniteHitsSessionStorageCache();
import { DrawerLeft, DrawerRight, Toolbar } from "./AppBar";


const drawerWidth = 280;

export const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => {
    console.log(open)
    let r = {
      flexGrow: 1,
      padding: 0, //theme.spacing(3),
      paddingBottom: 0,
      maxWidth: '100%',
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      ...(!open.left && !open.right && {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        [theme.breakpoints.up('sm')]: {
          marginLeft: `-${drawerWidth}px`,
          marginRight: `-${drawerWidth}px`,
          marginTop: (open.embed ? "45px" : "15px")
        }
      }),
      ...(!open.left && open.right && {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        [theme.breakpoints.up('sm')]: {
          marginLeft: `-${drawerWidth}px`,
          marginTop: (open.embed ? "45px" : "15px")
        }
      }),
      ...(!open.right && open.left && {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        [theme.breakpoints.up('sm')]: {
          marginRight: `-${drawerWidth}px`,
          marginTop: (open.embed ? "45px" : "15px")
        }
      }),
    }
    if (open.embed) {
      // r.marginTop = "45px";
      r[theme.breakpoints.up('xs')] = {
        marginTop: "35px"
      }
    }
    else {
      // r.marginTop = "50px";
      // r[theme.breakpoints.up('xs')] = {
      //   marginTop: "35px"
      // }
    }
    return r;
  },
);


const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  // marginTop: '480px',
  top: open.embed ? "initial" : 0,
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open.left && !open.right && {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: {sm:`${drawerWidth}px`},
    },
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  ...(!open.left && open.right && {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginRight: `${drawerWidth}px`,
    },
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  ...(open.left && open.right && {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px - ${drawerWidth}px)`,
      marginLeft: `${drawerWidth}px`,
      marginRight: `${drawerWidth}px`,
    },
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  ...(!open.left && !open.right && {
    width: `calc(100%)`,
    marginLeft: `0px`,
    marginRight: `0px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));


const Embed = styled('div', {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  position: 'absolute',
  textAlign: 'center',
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open.left && !open.right && {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: {sm:`${drawerWidth}px`},
    },
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  ...(!open.left && open.right && {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginRight: `${drawerWidth}px`,
    },
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  ...(open.left && open.right && {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px - ${drawerWidth}px)`,
      marginLeft: `${drawerWidth}px`,
      marginRight: `${drawerWidth}px`,
    },
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  ...(!open.left && !open.right && {
    width: `calc(100%)`,
    marginLeft: `0px`,
    marginRight: `0px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));


export function AppBarDrawer(props) {
  const drawerState = useSelector((state) => state.drawer);
  const theme = useSelector((state) => state.theme);

  let twitchRef = useRef(); 
  let [init,setInit] = useState(false);
  


  return (
    <>

    <Box sx={{ display: 'flex' }}>
      <CssBaseline

      />


      <AppBar position="fixed" open={drawerState}>
        <Toolbar />
      </AppBar>
      <DrawerLeft />
      <Main open={drawerState}>
        <>

        <Hits
          open={drawerState}
        />
      </>
      </Main>
      <DrawerRight />
    </Box>
    </>
  );
}
import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import { updateStreamerList } from '../../state/StreamerListState.js';
import { useSelector, useDispatch } from 'react-redux'
import dayjs from 'dayjs';
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)


export default function MediaControlCard(props) {
  const theme = useTheme();
  const streamerListState = useSelector((state) => state.streamerList.data);
  let liveTime = "";
  console.log(streamerListState)
  let useData = streamerListState.map((streamer) => streamer._source).find((streamer) => streamer.user_login == props.hit.channel.replace('#','').toLowerCase());
  if (useData) {
    liveTime = dayjs(useData.started).fromNow(true);
  }
  // if (Object.keys(streamerListState).indexOf(props.hit.channel.replace('#','')) != -1) {
  //   useData = streamerListState[props.hit.channel.replace('#','')]

  // }
  console.log(useData);
  if (!useData) {
    return (
      <></>
    )
  }
  return (
    <Card sx={{ display: 'flex', maxWidth: '800px !important'}}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <CardMedia
        component="img"
        sx={{ maxWidth: '800px' }}
        image={useData?.thumbnail_url.replace('{width}','1280').replace('{height}','720')}
        alt="Live from space album cover"
      />
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography component="div" variant="h5">
            { useData.title }
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" component="div">
            Streaming for { liveTime } ({ useData.viewers } viewers)
          </Typography>
        </CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
        { useData.tags.map((tag) => {
            return <Chip label={tag} />
        })}
        </Box>
      </Box>
      
    </Card>
  );
}
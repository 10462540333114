import { useSelector, useDispatch } from 'react-redux';
import { usePagination, useHits, useInstantSearch, Configure } from 'react-instantsearch';
import React, { useEffect, useCallback, useRef, useState, useMemo, createContext } from 'react';
import { Divider, IconButton } from "@mui/material";
import { KeyboardArrowUp, Loop } from '@mui/icons-material';
import { ChatContainerInner } from "./ChatContainer";
import { hitView } from "./MessageViews";
import { MovingComponent } from "react-moving-text";
import { setEmbed } from "../../state/UIState.js";
import dayjs from 'dayjs';
import { styled } from '@mui/material/styles';
import { updateStreamerList } from '../../state/StreamerListState.js';

import { HtmlTooltip} from "../../utils/hits.js";
import MediaControlCard from "./ChannelTooltip";

const ChannelInner = styled('div')(({ theme }) => ({
    color: 'grey',
    fontWeight: 'bold',
    cursor: 'pointer',
    display: 'inline',
}));

const LiveIndicator = styled('div')(({ theme, isLive }) => ({
    color: isLive ? 'red' : 'grey',
    fontWeight: '100',
    fontSize: '1.6em'
}));

const Channel = ({ onClick, hit }) => (
    <ChannelInner onClick={onClick}>
        <HtmlTooltip
            slotProps={{
                popper: {
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 0] } },
                    ],
                },
            }}
            placement="right"
            title={<MediaControlCard hit={hit} />}
        >
            <span>{hit.channel}</span>
        </HtmlTooltip>
    </ChannelInner>
);

export const RefContext = createContext({ top: null, bottom: null });

const ArrowButton = ({ pos, open, children }) => (
    <div style={{marginTop:'40px',width:'100%',height: '35px',textAlign:'center'}} className={`arrow-button ${pos} ${open ? 'open' : ''}`}>
        {children}
    </div>
);

const useVisibilityObserver = (ref, callback) => {
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            const isVisible = entries.some(entry => entry.isIntersecting);
            callback(isVisible);
        });
        if (ref.current) observer.observe(ref.current);

        return () => {
            if (ref.current) observer.disconnect();
        };
    }, [ref, callback]);
};

const Hits = (props) => {
    const { hits, results } = useHits(props);
    // const { hits, results, showMore, isFirstPage, isLastPage, showPrevious } = useInfiniteHits(props);
    const { refresh, setIndexUiState, status } = useInstantSearch();
    const { refine, currentRefinement, isLastPage, isFirstPage } = usePagination(props);
    const [hpp, setHPP] = useState(50);
    const [refreshInt, setRefreshInt] = useState(null);
    const [topVisible, setTopVisible] = useState(false);
    const [bottomVisible, setBottomVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingTop, setIsLoadingTop] = useState(false);
    const drawerState = useSelector((state) => state.drawer);
    const dispatch = useDispatch();
    const topRef = useRef();
    const bottomRef = useRef();

    const streamerListState = useSelector((state) => state.streamerList.data);

    const getStreamerState = (channel) => {
        return streamerListState.find(s => s._source.user_login == channel.replace('#',''));
    }

    const [curStreamerData, setCurStreamerData] = React.useState(null);
    useEffect(() => {
        if (props.liveIndicator) {
            dispatch(updateStreamerList())
        }
    }, []);

    useEffect(() => {
        if (window.location.hash.replace('#portrait','').length > 0) {
            setIndexUiState(prevIndexUiState => ({
                ...prevIndexUiState,
                refinementList: {
                    ...prevIndexUiState.refinementList,
                    channel: window.location.hash.replace('#portrait','').split(','),
                },
            }));
        }
    }, [setIndexUiState]);

    useVisibilityObserver(topRef, setTopVisible);
    useVisibilityObserver(bottomRef, setBottomVisible);

    useEffect(() => {
      console.log(topVisible, bottomVisible, isFirstPage, isLastPage)
        if (bottomVisible && isFirstPage) {
          console.log(refreshInt);
          if (!refreshInt) {

            const interval = setInterval(() => {
                if (isLoading) return;
                refresh();
                console.log(123)
                setIsLoading(true);
            }, 3000);
            setRefreshInt(interval);
          }
        } else if (!bottomVisible && refreshInt) {
            clearInterval(refreshInt);
            setRefreshInt(null);
        }
        else if (topVisible && !isLastPage) {
            if (isLoading) return;
          // showMore();
          refine(currentRefinement + 1);
        }

        return () => {
            if (refreshInt) clearInterval(refreshInt);
        };
    }, [bottomVisible, topVisible, isFirstPage, refreshInt, currentRefinement]);

    useEffect(() => {
      setHPP(isFirstPage ? 50 : 50);
        setIsLoading(status === 'stalled' || status === 'loading');
        setIsLoadingTop(status === 'stalled' || status === 'loading' && topVisible);
    }, [status, topVisible, isFirstPage, currentRefinement]);

    useCallback(() => {
        if (topVisible) {
            // refine(currentRefinement + 1);
            if (isLoading) return;
            refine(currentRefinement + 1);
            bottomRef.current.scrollIntoView({ block: "end" });
        } else if (bottomVisible) {
            if (isLoading) return;
            if (isFirstPage) {
                setIsLoading(true);
                refresh();
            } else {
                // refine(currentRefinement - 1);
                refine(currentRefinement - 1);
                topRef.current.scrollIntoView({ block: "start" });
            }
        }
    }, [isFirstPage, refresh, currentRefinement]);

    const userColors = useMemo(() => {
        const colors = {};
        hits.forEach(hit => {
            if (hit.userState?.color) {
                colors[hit.username] = hit.userState.color;
            }
        });
        return colors;
    }, [hits]);

    return (
        <>
            <ArrowButton pos="top" open={drawerState}>
                <IconButton disabled={isLoadingTop} onClick={() => {
                    if (!topVisible) {
                        topRef.current.scrollIntoView({ behavior: 'smooth', block: "start" });
                    } else if (!isLastPage) {
                        refine(currentRefinement + 1);
                        // showMore();
                    }
                }}>
                  {
    isLoadingTop ? (
        <MovingComponent
            type="rotateCW"
            duration="2000ms"
            delay="0s"
            direction="normal"
            timing="linear"
            iteration="infinite"
            fillMode="none"
        >
            <Loop />
        </MovingComponent>
    ) : (
        <KeyboardArrowUp />
    )
}
                </IconButton>
            </ArrowButton>

            <Configure hitsPerPage={hpp} />
            <ChatContainerInner className="gha chat-inner" open={drawerState}>
                <div id="top-scroller" ref={bottomRef} aria-hidden="true" />

                {hits.map((hit, ii) => {
                    const chandata = getStreamerState(hit.channel);
                    let gamename = '';
                    if (chandata) {
                        gamename = '('+chandata._source.game_name+', '+chandata._source.viewer_count+' viewers)';
                    }

                    const nextChannel = hits[ii + 1];
                    let showChannel = !nextChannel || hit.channel !== nextChannel.channel || dayjs(hit.timestamp).format('YYYY-MM-DD') !== (nextChannel && dayjs(nextChannel.timestamp).format('YYYY-MM-DD'));
                    
                    return (
                        <div key={ii}>
                            {showChannel && (
                                <>
                                    <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
                                    <Channel onClick={() => dispatch(setEmbed(hit.channel.replace('#', '')))} hit={hit} />
                                    <span className="timestamp-date">{dayjs(hit.timestamp).format("MMM DD, YYYY")}</span>
                                    <span className="timestamp-date">{gamename}</span>
                                </>
                            )}
                            <div className="hit">
                                {hitView({ hit, id: hits[ii].objectID }, userColors)}
                            </div>
                        </div>
                    );
                })}

                <div id="bottom-scroller" ref={topRef} aria-hidden="true" />
            </ChatContainerInner>

            <LiveIndicator isLive={bottomVisible && isFirstPage}>
                {bottomVisible && isFirstPage ? (
                    <MovingComponent
                        type="flash"
                        duration="20000ms"
                        delay="0s"
                        direction="normal"
                        timing="ease-in-out"
                        iteration="infinite"
                        fillMode="none">
                        LIVE
                    </MovingComponent>
                ) : (
                    <span style={{width: '100%', textAlign: 'center', cursor: 'pointer'}} onClick={(e) => {
                      if (!bottomVisible) {
                          bottomRef.current.scrollIntoView({ behavior: 'smooth', block: "start" });
                      } else if (!isFirstPage) {
                          refine(currentRefinement - 1);
                          // showPrevious();
                      }
                    }}>LIVE</span>
                )}
            </LiveIndicator>
        </>
    );
};

export { Hits };

import { createSlice } from '@reduxjs/toolkit'
import { createTheme } from '@mui/material/styles';


export const searchBeforeSlice = createSlice({
    name: 'searchBefore',
    initialState: {
        data: null
    },
    reducers: {
        setData: (state, newValue) => {
            state.data = newValue.payload;
        }
    },
})

// Action creators are generated for each case reducer function
export const { setData } = searchBeforeSlice.actions
export const { reducer } = searchBeforeSlice;
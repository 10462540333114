import React, { useState } from 'react';
import { useRange } from 'react-instantsearch';
import { MobileDateRangePicker } from '@mui/x-date-pickers-pro/MobileDateRangePicker';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { LicenseInfo } from '@mui/x-license';
LicenseInfo.setLicenseKey(
    '7684ecd9a2d817a3af28ae2a8682895aTz03NjEwMSxFPTE3MjgxNjc2MzEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
  );
const unsetNumberInputValue = '';

const Range = (props) => {
    const [value, setValue] = React.useState([
        dayjs('2024-04-04'),
        dayjs(),
      ]);
  const { start, range, canRefine, precision, refine } = useRange(props);
  const values = {
    min:
      start[0] !== -Infinity && start[0] !== range.min
        ? start[0]
        : unsetNumberInputValue,
    max:
      start[1] !== Infinity && start[1] !== range.max
        ? start[1]
        : unsetNumberInputValue,
  };
  const [prevValues, setPrevValues] = useState(values);

  const [{ from, to }, setRange] = useState({
    from: values.min?.toString(),
    to: values.max?.toString(),
  });

  if (values.min !== prevValues.min || values.max !== prevValues.max) {
    setRange({ from: values.min?.toString(), to: values.max?.toString() });
    setPrevValues(values);
  }
  return (
    <>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MobileDateRangePicker
            value={value}
            disabled={!canRefine}
            disableFuture
            minDate={dayjs('2024-04-04')}
            onChange={(newValue) => {
                setValue(newValue)
                console.log(newValue)
                console.log(Number(newValue[0]))
                refine([newValue[0] ? Number(newValue[0]) : undefined, newValue[1] ? Number(newValue[1]) : undefined]);
                // setRange({ from: newValue[0] || unsetNumberInputValue, to: newValue[1] });
            }}
          />
          </LocalizationProvider>
    </>
  )
  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();

        refine([from ? Number(from) : undefined, to ? Number(to) : undefined]);
      }}
    >
      <label>
        <input
          type="number"
          min={range.min}
          max={range.max}
          value={stripLeadingZeroFromInput(from || unsetNumberInputValue)}
          step={step}
          placeholder={range.min?.toString()}
          disabled={!canRefine}
          onInput={({ currentTarget }) => {
            const value = currentTarget.value;

            setRange({ from: value || unsetNumberInputValue, to });
          }}
        />
      </label>
      <span>to</span>
      <label>
        <input
          type="number"
          min={range.min}
          max={range.max}
          value={stripLeadingZeroFromInput(to || unsetNumberInputValue)}
          step={step}
          placeholder={range.max?.toString()}
          disabled={!canRefine}
          onInput={({ currentTarget }) => {
            const value = currentTarget.value;

            setRange({ from, to: value || unsetNumberInputValue });
          }}
        />
      </label>
      <button type="submit">Go</button>
    </form>
  );
}


export default Range;
import { createSlice, createAsyncThunk, buildCreateSlice } from '@reduxjs/toolkit'
import { createTheme } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux'
// var { Client } = require("@opensearch-project/opensearch");
// const ELASTIC_PASSWORD="y4kr.XiCwD7H7_X"
// const ELASTIC_USER="tim"
// const ELASTIC_URL="https://search-chat-454f6s74t4xgkpkul5dtw2aioa.us-east-1.es.amazonaws.com"

// const es = new Client({
//     node: ELASTIC_URL,
//     auth: {
//         username: ELASTIC_USER,
//         password: ELASTIC_PASSWORD
//     }
// });

let channelData = require('../utils/channel_data.json');
const streamerList = Object.keys(channelData);

export const updateStreamerList = createAsyncThunk(
    'content/fetchContent',
    async () => {
        // return { data: [] }
        const url = 'https://xdding-cors-51c39b1137be.herokuapp.com/https://search-chat-454f6s74t4xgkpkul5dtw2aioa.us-east-1.es.amazonaws.com/streamer-state/_search';
    const body = {
        query: {
            range: {
                timestamp: {
                    gte: "now-90s"
                }
            }
        },
        sort: { timestamp: "desc" },
        size: 70
        }

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Basic dGltOnk0a3IuWGlDd0Q3SDdfWA=='
            },
            body: JSON.stringify(body)
        });

        const data = await response.json();
        return { data: data.hits.hits };
    } catch (error) {
        console.error('Error fetching data:', error);
    }
        // return es.search({
        //     index: 'streamer-state',
        //     body: {
        //         query: {
        //             range: {
        //                 timestamp: {
        //                     gte: "now-90s"
        //                 }
        //             }
        //         },
        //         sort: { timestamp: "desc" },
        //         size: 70
        //     }
        // }).then((res) => {
        //     return { data: res.body.hits.hits }
        // }).catch((err) => {
        //     console.error(err)
        // })
    }
)

export const streamerListSlice = createSlice({
    name: 'streamerList',
    initialState: {
        data: {}
    },
    reducers: {

    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(updateStreamerList.fulfilled, (state, action) => {
          // Add user to the state array
          state.data = action.payload.data;
        })
      },
})

// Action creators are generated for each case reducer function
export const { reducer } = streamerListSlice;
import { styled } from '@mui/material/styles';
import { parseBadges } from "../../utils/badges";
import { calcLuma, handleMentionsAndLinks, HtmlTooltip } from "../../utils/hits.js";
import { setData } from "../../state/SearchBeforeState.js";
import { Divider, Link, Typography, IconButton } from "@mui/material";
import dayjs from 'dayjs';
import { MovingComponent } from "react-moving-text"
const humanizeDuration = require("humanize-duration");
import { Reply } from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux'




const userView = ({hit,filterOnUser}) => {
    var imgUrls = [];
  
    if (hit.userState && hit.userState['badges-raw']) {
      imgUrls = parseBadges(hit.userState['badges-raw'],hit.channel);
    }
    imgUrls.forEach((img) => {
      if (img === null) {
        console.log(imgUrls)
        console.log(hit)
      }
    })
    let userStyles = {}

    if (hit.userState?.color) {
        if (calcLuma(hit.userState.color) < 10) {
            userStyles.WebkitTextStrokeWidth = '0.25px';
            userStyles.WebkitTextStrokeColor = 'white';
        }
        userStyles.color = hit.userState.color;
    }
    return (
      <span className="badges-container">
        <span class="message-badges">
          { imgUrls.filter(badge => badge != null).map((badge) => (
            <HtmlTooltip
            slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -16],
                      },
                    },
                  ],
                },
              }}
                placement="top"
                title={
                    <>
                        <Typography variant="h6">{badge?.title}</Typography>
                        <Typography variant="subtitle1">{badge?.description}</Typography>
                    </>
                }
            >
            <img src={badge.imageURL} />
            </HtmlTooltip>
          )) }
        </span>
        <span 
            className="username" 
            style={userStyles}
            onClick={() => filterOnUser(hit.username)}
        >
          {hit.userState && hit.userState['display-name'] ? hit.userState['display-name'] : hit.username}
        </span>
      </span>
    )
  }
  
  const messageView = ({hit,filterOnUser, deleted},userColors) => {
    let usestyle = {display:'inline'}
    if (deleted) {
        usestyle = {'textDecoration':'line-through','color':'rgba(255, 255, 255, 0.3) !important','fontStyle':'italic'}
    }
    let inthing = userView({hit,filterOnUser})
    let extrastyle = {};
    if (hit.type == 'transcript') {
      inthing = (<span></span>)
      extrastyle.fontStyle = 'italic'
    }
    return (
    <div style={usestyle}>
        { hit.type != 'transcript' && 
          userView({hit,filterOnUser})
         }
         { hit.type != 'transcript' && 
          <span>: </span>
         }
        <span className="message" style={extrastyle}>
            {handleMentionsAndLinks(hit,userColors)}
            {/* <span dangerouslySetInnerHTML={{__html:handleMentionsAndLinks(msgToSend,userColors) }}/> */}
            </span>
    </div>
    )
  }
  
  const joinPartView = (hit,joinPart) => {
    return (
      <span className="system-message">
        { userView({hit}) }
        <span className="message">{joinPart}</span>
      </span>
    )
  }
  
  const subView = (hit,subOrRe) => {
    return (
      <span className="sub-message">
        { userView({hit}) }
        <span className="message">just {subOrRe}</span>
        { hit.data?.method?.prime &&
          " with prime"
        }
      </span>
    )
  }
  const giftSubView = (hit) => {
    return (
      <span className="sub-message">
        { userView({hit}) }: 
        <span className="message">
            {hit.userState['system-msg']}
        </span>
      </span>
    )
  }

  const raidedView = (hit) => {
    return (
        <span className="raided-message">
            { userView({hit}) }
            <span className="message">
            raided the channel with 
            <MovingComponent
  type="effect3D"
  duration="1300ms"
  delay="0s"
  direction="normal"
  timing="linear"
  iteration="infinite"
  fillMode="none">{ hit.data?.viewers } viewers</MovingComponent>
            </span>
            </span>
    )
  }
  
  const cheerView = (hit) => {
    const amt = parseInt(hit.userState.bits);
    var bitsImage;
    if (amt < 100) {
      bitsImage = "https://media.tenor.com/NdtjZXilqeEAAAAi/cheer1-twitch.gif";
    }
    else if (amt >= 100 && amt < 1000) {
      bitsImage = "https://media.tenor.com/izJVS6Wb-lYAAAAi/bits.gif";
    }
    else if (amt >= 1000 && amt < 5000) {
      bitsImage = "https://media.tenor.com/ml0XFsCUgxwAAAAi/twitch-rpx_syria.gif";
    }
    else if (amt >= 5000 && amt < 10000) {
      bitsImage = "https://media.tenor.com/pT6HQx4wIogAAAAi/twitch-rpx_syria.gif";
    }
    else {
      bitsImage = "https://media.tenor.com/cxAQToMOeykAAAAi/twitch-rpx_syria.gif";
    }

    return (
      <span className="cheer-message">
        { userView({hit}) }
        <span className="message">just cheered <img src={bitsImage}/> {amt}</span>
      </span>
    )
  }
  const emoteOnlyView = (hit) => {
    let type;
    if (hit.type == "followeronly") type = "Followers";
    else if (hit.type == "emoteonly") type = "Emotes";
    else if (hit.type == "subscribers") type = "Subscribers";
    return (
      <span className="system-message">
        {type} only mode {hit.data?.enabled ? "enabled" : "disabled"}
      </span>
    )
  }
  const clearChatView = (hit) => {
    return (
      <span className="system-message">
        Chat was cleared by a moderator
      </span>
    )
  }
  const slowModeView = (hit) => {
    return (
      <span className="system-message">
        Slow mode has been
        { hit.data?.enabled ? " enabled" : " disabled" }
        { hit.data?.length ? " for " + humanizeDuration(hit.data?.length*1000): ""}
      </span>
    )
  }
  const replyView = (hit) => {
    return (
      <div style={{color: 'grey'}}>
        <Reply style={{fontSize:'1rem'}}/>
        Replying to @{hit.userState['reply-parent-display-name']}: {hit.userState['reply-parent-msg-body']}
      </div>
    )
  }

 export const hitView = ({ hit, filterOnUser, refresh, id },userColors) => {
    const dispatch = useDispatch();
    var msgHTML;
    if (hit.type == "message") {
      msgHTML = messageView({hit,filterOnUser},userColors);
    }
    else if (hit.type == "part") {
      msgHTML = joinPartView(hit,"left the chat")
    }
    else if (hit.type == "join") {
      msgHTML = joinPartView(hit,"entered the chat")
    }
    else if (hit.type == "subscription") {
      msgHTML = subView(hit,"subscribed");
    }
    else if (hit.type == "resub") {
      msgHTML = subView(hit,"resubscribed");
    }
    else if (hit.type == "subgift") {
        msgHTML = giftSubView(hit);
      }
      else if (hit.type == "submysterygift") {
        msgHTML = giftSubView(hit);
      }
    else if (hit.type == "ban") {
      msgHTML = joinPartView(hit,"was banned");
    }
    else if (hit.type == "timeout") {
      msgHTML = joinPartView(hit,"was timed out for " + humanizeDuration(hit.data?.duration*1000))
    }
    else if (hit.type == "cheer") {
      msgHTML = cheerView(hit);
    }
    else if (hit.type == "raided") {
        msgHTML = raidedView(hit);
    }
    else if (hit.type == "emoteonly") {
        msgHTML = emoteOnlyView(hit);
    }
    else if (hit.type == "clearchat") {
        msgHTML = clearChatView(hit);
    }
    else if (hit.type == "followersonly") {
        msgHTML = emoteOnlyView(hit);
    }
    else if (hit.type == "subscribers") {
        msgHTML = emoteOnlyView(hit);
    }
    else if (hit.type == "slowmode") {
        msgHTML = slowModeView(hit);
    }
    else if (hit.type == "messagedeleted") {
        msgHTML = messageView({hit,filterOnUser,deleted:true}, userColors);
    }
    else if (hit.type == 'transcript') {
      msgHTML = messageView({hit,filterOnUser}, userColors);
    }
  
    return (
      <div>
        <div className="message-inner">
          <div>            { hit.userState && Object.keys(hit.userState).indexOf('reply-parent-msg-body') > -1 &&
            replyView(hit)
          }
</div>
          <div>
            <span class="timestamp" onClick={(e) => {
                    console.log(id)
                    // dispatch(setData(id))
                    // refresh();
                  }}>{dayjs(hit.timestamp).format("hh:mm A")}</span>
            { msgHTML }
            </div>
          
          {/* <span
            className="message"
            dangerouslySetInnerHTML={{
              __html: replaceBTTVAll(hit.message, hit.channel),
            }}
          /> */}
        </div>
      </div>
    );
  };
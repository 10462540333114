// src/index.tsx
import React from 'react';
import ReactDOM from 'react-dom';
import store from './store'
import { Provider } from 'react-redux'

import App from './App'; // Main app component

if (window.location.hash.length > 0 && window.location.hash.indexOf('portrait') > -1) {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <div style={{
          width: '100%',
          height:'100%',
          margin: 0,
          padding: 0,
          overflow: 'hidden',
          position: 'absolute'
        }}>
          <div style={{
            width: '100vh',
            height: 'calc(100vw + 75%)',
            transform: 'rotate(270deg) scaleY(0.7)',
            transformOrigin: 'right bottom',
            position: 'absolute',
            bottom:'100%',
            left: '43%'
          }}>
          <App />
          </div>
        </div>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  );
}
else {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  );
}
import { Divider, Link, Typography, IconButton } from "@mui/material";
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const seventvmap = require('../utils/seventvmap.json');


export const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
      fontSize: theme.typography.pxToRem(12),
      maxWidth: '800px'
    //   border: '1px solid #dadde9',
    },
  }));

export const findReplacedWords = (str1, str2) => {
    const tokens1 = str1.split(/[\s+]/).filter(s => s.length > 0); // Split by whitespace
    const tokens2 = str2.replaceAll(/(<img .*?\/>)/g, " REPLACED ").split(/[\s+]/).filter(s => s.length > 0);
    // const tokens2 = str2.split(/(<img[^>]*>)/); // Split by <img> tags and keep them
    let replacedWords = [];
    let index1 = 0;
    let index2 = 0;
  
    while (index1 < tokens1.length && index2 < tokens2.length) {
      if (tokens2[index2]=="REPLACED") {
        // Check if previous tokens match; if not, it means the word at index1 was replaced by the img tag at index2
        replacedWords.push(tokens1[index1]);

      }
        // If they don't match and it's not an image, skip the second string forward to catch up
        index2++;
        index1++;
      
    }
  
    return replacedWords;
  }

  export const calcLuma = (hex) => {
    const c = hex.substring(1);
    let rgb = parseInt(c, 16);
    let r = (rgb >> 16) & 0xff;
    let g = (rgb >> 8) & 0xff;
    let b = (rgb >> 0) & 0xff;
    return 0.2126 * r + 0.7152 * g + 0.0722 * b;
  }

  export const handleMentionsAndLinks = (hit, colorMap) => {

    let seventvemotes = seventvmap[hit.channel.replace('#','')]?.emotes || [];

    let text = hit.data?.messageParsed ? hit.data.messageParsed : hit.data?.message;
    let replacedEmotes = [];
    if (hit.data?.messageParsed) {
        replacedEmotes = findReplacedWords(hit.data?.message,hit.data?.messageParsed)
    }
    // Regular expression for URLs and @usernames
    const urlRegex = /(?<!<img[^>]*?src=["'])https?:\/\/[^\s<]+/g;
    const userRegex = /@(\w+)/g;

    // Split the string into parts and map each part
    const parts = text.trim().split(/(<img[^>]*>|\bhttps?:\/\/[^\s<]+|@\w+)/);

    return parts.map((part, index) => {
        if (part.startsWith('<img')) {
            const emoteName = replacedEmotes.shift();
            // Return <img> tags as is
            return (
                <HtmlTooltip
                slotProps={{
                    popper: {
                      modifiers: [
                        {
                          name: 'offset',
                          options: {
                            offset: [0, -16],
                          },
                        },
                      ],
                    },
                  }}
                    placement="top"
                    title={
                        <span className="img-tooltip">
                            <div className="tooltip-img" dangerouslySetInnerHTML={{__html:part}}/>
                            <Typography variant="caption">{emoteName}</Typography>
                        </span>
                    }
                >
                    <span key={index} dangerouslySetInnerHTML={{ __html: part }} />
                </HtmlTooltip>
            )
          }
      else if (part.match(urlRegex)) {
        // If part is a URL, wrap it in an <a> tag
        return <Link color="inherit" key={index} href={part} target="_blank" rel="noopener noreferrer">{part}</Link>;
      } else if (part.match(userRegex)) {
        // If part is a username, get the username without '@'
        const username = part.slice(1);
        const color = colorMap[username.toLowerCase()] || 'inherit';  // Default color if not specified
        // Wrap username in a <span> with the color
        return <span key={index} style={{ color, fontWeight: 'bold' }}>{part}</span>;
      } else {
        // Return the part as is if it's neither
        seventvemotes.forEach((emote) => {
            let imghtml = `<img style="height:18px" src="${emote.url.replace('4x','1x')}"/>`;
            part = part.replace(`(\b`+emote.name+`\b)`,imghtml)
            return;
            if (part.indexOf(' ' + emote.name + ' ') !== -1) {
                part = part.replace(' ' + emote.name + ' ',' ' + imghtml + ' ')
            }
            else if (part.indexOf(' ' + emote.name) !== -1) {
                part = part.replace(' ' + emote.name,' ' + imghtml)
            }
            else if (part.indexOf(emote.name) + ' ' !== -1) {
                part = part.replace(emote.name + ' ',imghtml + ' ')
            }
            else if (part == emote.name) {
                part = imghtml;
            }
            // var re = new RegExp("\\b"+emote.name.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')+"\\b","g");
            // console.log("\\b"+emote.name.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')+"\\b")
            // part = part.replace(re,)
        })
        return <span dangerouslySetInnerHTML={{__html:part}} />
      }
    });
  };
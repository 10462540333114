import {
  useToggleRefinement,
  useRefinementList
} from "react-instantsearch";
import React, { useEffect, useRef, useMemo} from 'react';

import LensIcon from '@mui/icons-material/Lens';
import { Switch, TextField, FormControlLabel, Checkbox } from "@mui/material";
import { MovingComponent } from "react-moving-text"

let channelData = require('../utils/channel_data.json');
const streamerList = Object.keys(channelData);
export function ToggleRefinement(props) {
  const { value, refine, canRefine } = useToggleRefinement(props);

  return (
    <FormControlLabel
      control={
        <Switch
          type="checkbox"
          disabled={canRefine}
          checked={value.isRefined}
          onChange={(event) => {
            console.log(event);
            refine({ isRefined: !event.target.checked });
          }}
        />
      }
      label={props.label}
    />
  );
}

export function RefinementList(props) {
  const {
    items,
    refine,
    searchForItems,
    canToggleShowMore,
    isShowingMore,
    toggleShowMore,
  } = useRefinementList(props);

  const { useTimer } = props;

  const [curStreamerData, setCurStreamerData] = React.useState(null);
  useMemo(() => {

    function fetcher() {
      console.log(streamerList);
      const queryParams = streamerList.map(user => `user_login=${encodeURIComponent(user)}`).join('&');
      const baseURL = 'https://api.twitch.tv/helix/streams?type=all&first=50&';
      const url = baseURL + queryParams;
      fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ouu24v9u6lakhacq5tod6z3dkeockc',
          'Client-Id': 'gp762nuuoqcoxypju8c569th9wz7q5'
        }
      })
        .then(response => response.json())
        .then(data => {
          console.log(data)
          setCurStreamerData({ last_checked: new Date(), data: data })
        })
        .catch(error => console.error('Error:', error));
    }
    if (false) {

      const id = setInterval(() => {

        if (useTimer && curStreamerData == null) {
          fetcher();
        }
        else if (useTimer) {
          const last = curStreamerData.last_checked;
          const now = new Date();
          const diff = now - last;
          if (diff > 60000) {
            fetcher();
          }
        }

      }, 60000)
      return () => { if (id) clearInterval(id) }

    }
      if (useTimer && curStreamerData == null) {
        fetcher();
    }
    
  }, []);

let streamerData = {}
if (curStreamerData && curStreamerData.data) {
  curStreamerData.data.data.forEach((streamer) => {
    streamerData[streamer.user_login] = {
      game: streamer.game_name,
      title: streamer.title,
      tags: streamer.tags,
      viewers: streamer.viewer_count
    }
  })

}

return (
  <>
    {props.allowSearch &&
      <TextField
        size="small"
        fullWidth
        label="Search for User"
        variant="outlined"
        onChange={(event) => {
          searchForItems(event.target.value);
        }}
      />
    }
    {items.map((item) => {
      let extra = null;
      if (Object.keys(streamerData).indexOf(item.label.replace('#', '')) != -1) {
        const sdata = streamerData[item.label.replace('#', '')];
        extra = (
          <span>
            <MovingComponent
              type="flash"
              duration="8000ms"
              delay="2s"
              direction="normal"
              timing="linear"
              iteration="infinite"
              fillMode="none">
              <LensIcon fontSize="inherit" />

            </MovingComponent>
          </span>
        )
      }
      return (
        <div key={item.label}>
          <FormControlLabel
            label={
              <span>
                {extra ? extra : null}
                <span>{item.label}</span>
                <span className="item-count">{" (" + item.count + ")"}</span>
              </span>
            }
            control={
              <Checkbox
                style={{
                  paddingBottom: 0,
                  paddingTop: 0,
                }}
                checked={item.isRefined}
                onChange={(event) => {
                  refine(item.value);
                }}
              />
            }
          />
        </div>
      )
    })}
  </>
);
}
import {
    useHits,
    useInstantSearch
  } from "react-instantsearch";
  import moment from "moment";
  const humanizeDuration = require("humanize-duration");
  import { useEffect, useMemo, useRef, useState} from "react";
  
import { updateOnlineUsersList } from '../state/OnlineUsersState.js';
import { useSelector, useDispatch } from 'react-redux'
  import { Divider, Typography } from "@mui/material";
require('array.prototype.flatmap').shim()
  
  import { parseBadges } from "../utils/badges";



  const calcLuma = (hex) => {
    const c = hex.substring(1);
    let rgb = parseInt(c, 16);
    let r = (rgb >> 16) & 0xff;
    let g = (rgb >> 8) & 0xff;
    let b = (rgb >> 0) & 0xff;
    return 0.2126 * r + 0.7152 * g + 0.0722 * b;
  }
  const shortEnglishHumanizer = humanizeDuration.humanizer({
    language: "shortEn",
    languages: {
      shortEn: {
        y: () => "y",
        mo: () => "mo",
        w: () => "w",
        d: () => "d",
        h: () => "h",
        m: () => "m",
        s: () => "s",
        ms: () => "ms",
      },
    },
  });
  
function renderUser(user,data) {
    let userStyles = {}

    if (data.color) {
        if (calcLuma(data.color) < 10) {
            userStyles.WebkitTextStrokeWidth = '0.25px';
            userStyles.WebkitTextStrokeColor = 'white';
        }
        userStyles.color = data.color;
    }
    else if (data.backupcolor) {
        if (calcLuma(data.backupcolor) < 10) {
            userStyles.WebkitTextStrokeWidth = '0.25px';
            userStyles.WebkitTextStrokeColor = 'white';
        }
        userStyles.color = data.backupcolor;
    }
    return (
        <div key={"user-"+user.user}>
            { data.isSub && 
                <span className="message-badges"><img src="https://static-cdn.jtvnw.net/badges/v1/25a03e36-2bb2-4625-bd37-d6d9d406238d/1"/></span>
            }
            <span style={userStyles}>{ user.user }</span>
            <span style={{fontSize:"0.8em"}}> ({user.doc_count})</span>
            { user.time && 
                <span style={{color:"lightgray",textTransform:"uppercase",fontSize:"0.8em"}}> {shortEnglishHumanizer(new Date().getTime() - user.time,{largest:2,round:true,spacer:"",delimiter:" "})}</span>
            }
            {/* <span className="user-msg-count">({data.count})</span> */}
        </div>
    )
}

const OnlineUsers = (props) => {
    //   const { hits, sendEvent } = useHits(props);
    // const onlineUsersState = useSelector((state) => state.onlineUsers);
    const { uiState } = useInstantSearch();
      const { hits } = useHits(props);//({cache: sessionStorageCache});
      const [users, setUsers] = useState([])
      const fetchTimeoutRef = useRef(null);
      const curchans = [...new Set(hits.map((hit) => hit.channel))];

    //   const dispatch = useDispatch();
      useEffect( () => {
        async function fetcher() {
            let test = null;
            try {
                test = uiState['chatlogs-alias'].refinementList.channel;
            }
            catch {
                test = null;
            }
            console.log(test);
            console.log(curchans)
            let useChans = curchans;
            if (test) {
                useChans = test;
            }
            const url = 'https://xdding-cors-51c39b1137be.herokuapp.com/https://search-chat-454f6s74t4xgkpkul5dtw2aioa.us-east-1.es.amazonaws.com/chatlogs-alias/_search';
            const body = {
                size: 0,
                query: {
                    bool: {
                        should: [
                            { term: { "type.keyword": "join" } },
                            { term: { "type.keyword": "part" } }
                        ],
                        must: [
                            
                        ],
                        minimum_should_match: 1
                    }
                },
                aggs: {
                    by_channel: {
                        terms: {
                            field: "channel.keyword",
                            size: 10
                        },
                        aggs: {
                            by_user: {
                                terms: {
                                    field: "usernameNormalized.keyword",
                                    size: 150
                                },
                                aggs: {
                                    latest_join: {
                                        max: {
                                            field: "timestamp",
                                            script: {
                                                source: "doc['type.keyword'].value == 'join' ? doc['timestamp'].value.toInstant().toEpochMilli() : 0"
                                            }
                                        }
                                    },
                                    latest_part: {
                                        max: {
                                            field: "timestamp",
                                            script: {
                                                source: "doc['type.keyword'].value == 'part' ? doc['timestamp'].value.toInstant().toEpochMilli() : 0"
                                            }
                                        }
                                    },
                                    currently_in_channel: {
                                        bucket_selector: {
                                            buckets_path: {
                                                joinTime: "latest_join",
                                                partTime: "latest_part"
                                            },
                                            script: "params.joinTime > params.partTime"
                                        }
                                    },
                                    "sorted_by_latest_join": {
                                        "bucket_sort": {
                                            "sort": [
                                                {
                                                    "latest_join.value": { "order": "desc" }
                                                }
                                            ]
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            };
            if (useChans.length > 0) {
                body.query.bool.must.push({terms: { "channel.keyword": useChans }})
            }
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic dGltOnk0a3IuWGlDd0Q3SDdfWA=='
                },
                body: JSON.stringify(body)
            });
            const res = await response.json();
            console.log(res);
        const t = res.aggregations.by_channel.buckets.map((channel) => {
            return {channel: channel.key, users: channel.by_user.buckets.map((user) => {
                return {
                    'user': user.key,
                    'doc_count': user.doc_count,
                    'time': user.latest_join.value,
                    'color': 'white',//user.color.buckets.length > 0 ? user.color.buckets[0].key : 'black'
                }
            })}
        }).sort((a,b) => a.time > b.time);
        setUsers(t);
    }
    if (fetchTimeoutRef.current) {
        clearInterval(fetchTimeoutRef.current);
    }

    fetchTimeoutRef.current = setInterval(fetcher, 15000);

    return () => {
        if (fetchTimeoutRef.current) {
            clearInterval(fetchTimeoutRef.current);
        }
    };
    }, [uiState]);

      let allUsers = {};
      hits.forEach((hit) => {
        if (hit.username) {
            // console.log(hit.userState)
            if (Object.keys(allUsers).indexOf(hit.username) == -1) {
                allUsers[hit.username] = {
                    color: 'black',
                    type: 'viewer',
                    count: 1,
                    isMod: false,
                    isVIP: false,
                    isSub: false
                }
            }
            else {
                allUsers[hit.username].count += 1;
            }
            if (hit.userState?.badges?.broadcaster) {
                allUsers[hit.username].isBroadcaster = true;
            }
            if (hit.userState?.color) {
                
                allUsers[hit.username].color = hit.userState.color;
            }
            if (hit.userState?.mod) {
                allUsers[hit.username].isMod = true;
            }
            else if (hit.userState) {
                if (allUsers[hit.username].isMod) {
                }
                allUsers[hit.username].isMod = false;
            }
            if (hit.userState?.vip) {
                allUsers[hit.username].isVIP = true;
            }
            else if (hit.userState) {
                allUsers[hit.username].isVIP = false;
            }
            if (hit.userState?.subscriber) {
                allUsers[hit.username].isSub = true;
            }
            else if (hit.userState) {
                allUsers[hit.username].isSub = false;
            }
            
        }
      })
      return (
        <>
            { users.map((channel) => {

                return (
                    <div>
                    <Typography sx={{display:'table',margin:'0 auto'}} variant="button" gutterBottom>
                            {channel.channel.replace('#','')}
                          </Typography>
                      { channel.users.map(u => {
                          return (
                            <>
                                { renderUser(u,{backupcolor: u.color, ...allUsers[u.user]}) }
                            </>
                          )
                      })}
                      </div>
                )
            })
        }
        </>
      )
        return (
          <>
          <div>
          <Typography variant="overline" gutterBottom>
                  Streamers
                </Typography>
            { Object.keys(allUsers).filter(user => allUsers[user].isBroadcaster).map(user => {
                return renderUser(user,allUsers[user])
            })}
            </div>
          <div>
          <Typography variant="overline" gutterBottom>
                  Mods
                </Typography>
            { Object.keys(allUsers).filter(user => allUsers[user].isMod).map(user => {
                return renderUser(user,allUsers[user])
            })}
            </div><div>
            <Typography variant="overline" gutterBottom>
                  VIPs
                </Typography>
             { Object.keys(allUsers).filter(user => allUsers[user].isVIP && !allUsers[user].isMod).map(user => {
                                return renderUser(user,allUsers[user])

            })}
            </div><div>
            <Typography variant="overline" gutterBottom>
                  Viewers
                </Typography>
             { Object.keys(allUsers).filter(user => !allUsers[user].isMod && !allUsers[user].isVIP && !allUsers[user].isBroadcaster).map(user => {
                                return renderUser(user,allUsers[user])

            })}
            </div>
          </>
        );
    }

    export default OnlineUsers;
import { configureStore } from '@reduxjs/toolkit'
import { reducer as drawerReducer } from './state/UIState.js';
import { reducer as streamerListReducer } from './state/StreamerListState.js';
import { reducer as hitsReducer } from './state/HitsState.js';
import { reducer as beforeReducer } from './state/SearchBeforeState.js';
import { reducer as onlineUsersReducer } from './state/OnlineUsersState.js';


export default configureStore({
  reducer: {
    drawer: drawerReducer,
    streamerList: streamerListReducer,
    onlineUsers: onlineUsersReducer,
    hits: hitsReducer,
    searchBefore: beforeReducer
  },
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: false,
  }),
})
import { FormControlLabel, Switch } from '@mui/material';
import {
    useToggleRefinement,
  } from "react-instantsearch";
import React, { useEffect, useRef, useMemo} from 'react';


const ToggleRefinement = (props) => {
    const { value, refine, canRefine } = useToggleRefinement(props);
  
    return (
      <FormControlLabel
        control={
          <Switch
            type="checkbox"
            checked={value.isRefined}
            onChange={(event) => {
              console.log(event);
              refine({ isRefined: !event.target.checked });
            }}
          />
        }
        label={props.label}
      />
    );
  }

  export default ToggleRefinement;
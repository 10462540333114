import Searchkit from "searchkit";
import Client from "@searchkit/instantsearch-client";

import { ESTransporter } from 'searchkit'
 
class MyTransporter extends ESTransporter {
  async performNetworkRequest(requests) {
    // for (var request of requests) {
    //   request.body.preference = 'chatlogs-viewer'
    // }
    let body = this.createElasticsearchQueryFromRequest(requests)
    // body = body.replaceAll(`"index":"chatlogs-alias"`, `"index":"chatlogs-alias","preference":"chatlogs-viewer"`)
    
    // you can use any http client here
    return fetch(`https://search-chat-454f6s74t4xgkpkul5dtw2aioa.us-east-1.es.amazonaws.com/chatlogs-alias/_msearch`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Basic dGltOnk0a3IuWGlDd0Q3SDdfWA=='
      },
      body: body,
      method: 'POST'
    })
  }
}

const sk = new Searchkit({
  connection: new MyTransporter({

  },{
    debug: false
  }), //
  search_settings: {
    search_attributes: [{ field: "data.message.keyword" }],
    result_attributes: [
      "timestamp",
      "channel",
      "username",
      "type",
      "data",
      "userState"
    ],
    highlight_attributes: ["data.message.keyword"],
    facet_attributes: [
      { attribute: "channel", field: "channel.keyword", type: "string" },
      { attribute: "mentions", field: "data.mentions.keyword", type: "string" },
    //   { attribute: "emotes", field: "userState.emotes-raw.keyword", type: "string",
    //   filterQuery: (field, value) => {
    //     return {
    //       regexp: {
    //         [field]: ".*"+value+":.*(,)?"
    //       }
    //     }
    //   },
    //   facetQuery: (
    //     field, // 'actors.keyword' in this case
    //     size,  // size specified by the frontend
    //     query // query specified by the frontend, used for search within facet
    //   ) => ({
    //     "terms": {
    //       "script": `
    //       def ret = [];
          
    //       if (doc['userState.emotes-raw.keyword'].size() > 0) {
    //         def emotes = doc['userState.emotes-raw.keyword'].value;
    //         def ddd = emotes.splitOnToken('/');
    //         for (d in ddd){
    //           def dd = d.splitOnToken(':');
    //           def key = dd[0];//subs+'|'+dd[0];
    //           ret.add(key);
    //         }
            
    //       }
    //       return ret;
    //       `,
    //     }
    //   }),
    // },
    // { attribute: "curses", field: "data.message", type: "string",
    //   filterQuery: (field, value) => {
    //     return {
    //       regexp: {
    //         [field]: ".*"+value.replace(/([A-Za-z])/g,function(s){return '['+s.toUpperCase()+'|'+s.toLowerCase()+']'})+".*"
    //       }
    //     }
    //   },
    //   facetQuery: (
    //     field, // 'actors.keyword' in this case
    //     size,  // size specified by the frontend
    //     query // query specified by the frontend, used for search within facet
    //   ) => ({
    //         "filters": {
    //           "filters": {
    //             "fuck": { "regexp": { "data.message.keyword": ".*fuck.*"}},
    //             "shit": { "regexp": { "data.message.keyword": ".*shit.*"}},
    //             "cunt": { "regexp": { "data.message.keyword": ".*cunt.*"}},
    //             "bitch": { "regexp": { "data.message.keyword": ".*bitch.*"}},
    //             "ass": { "regexp": { "data.message.keyword": ".*asshole.*"}}
    //           }
    //     }
    //   }),
    // },
      { attribute: "username", field: "usernameNormalized.keyword", type: "string" ,
      filterQuery: (field, value) => {
        return {
          terms: {
            [field]: [value,value.toLowerCase()]
          }
        }
      }
     },
      { attribute: "type", field: "type.keyword", type: "string" },
      { attribute: "timestamp", field: "timestamp", type: "numeric",
      filterQuery: (field, value) => {
        return {
          range: {
            [timestamp]: {
              gte: value[0],
              lte: value[1]
            }
          }
        }
      }
     },
      {
        attribute: "showJoinParts",
        field: "showJoinParts",
        type: "boolean",
        filterQuery: (
          field, // 'actors.keyword' in this case
          value // value specified by the frontend
        ) => {
          if (value == "false") {
            return {
              bool: {
                must_not: {
                  terms: {
                    // example of using match clause instead of terms filter
                    type: ["join", "part"],
                  }
                }
              }
              
            };
          } else {
            return {
              exists: {
                // example of using match clause instead of terms filter
                field: "timestamp",
              },
            };
          }
        },
      },{
        attribute: "sarahMode",
        field: "sarahMode",
        type: "boolean",
        filterQuery: (
          field, // 'actors.keyword' in this case
          value // value specified by the frontend
        ) => {
          if (value == "false") {
            return {
              bool: {
                must: {
                  terms: {
                    // example of using match clause instead of terms filter
                    channel: ["mrstrashpanda", "nukachris","retroprofrank","mesar_","nickkehh","chlohno","slothninja597","xdarkassassinz","peanitt","tipperbackhoe4"],
                  }
                }
              }
              
            };
          } else {
            return {
              exists: {
                // example of using match clause instead of terms filter
                field: "timestamp",
              },
            };
          }
        },
      },{
        attribute: "modsOnly",
        field: "modsOnly",
        type: "boolean",
        filterQuery: (
          field, // 'actors.keyword' in this case
          value // value specified by the frontend
        ) => {
          if (value == "true" || value == true) {
            return {
              bool: {
                must: {
                  term: {
                    "userState.mod": true
                  }
                }
              }
              
            };
          } else {
            return {
              exists: {
                // example of using match clause instead of terms filter
                field: "timestamp",
              },
            };
          }
        },
      },
    ],
    sorting: {
      default: {
        field: "timestamp",
        order: "desc",
      },
    },
  },
});

export const getSearchkitClient = (props) => {
  return Client(sk, {
  getQuery(query, search_attributes, config) {
    if (false) {
      return {
        match_all: {}
      }
    }
    return {
      regexp: {
        "data.message.keyword": ".*" + query + ".*",
      },
    };
  },
  hooks: {
    // afterSearch: async (searchRequests, searchResponses) => {
    //   return new Promise((resolve, reject) => {
    //     searchResponses.forEach((response) => {
    //       if (response.aggregations) {
    //       if (response.aggregations?.emotes?.value) {
    //         response.aggregations.emotes.buckets = response.aggregations.emotes.value;
    //         delete response.aggregations.emotes.value;
    //       }
    //       if (response.aggregations?.curses) {
    //         console.log(response.aggregations.curses.buckets)
    //         response.aggregations.curses.buckets = Object.entries(response.aggregations.curses.buckets).map(([key, value]) => ({key, doc_count: value.doc_count}))
    //       }
    //     }
    //     })

    //     resolve(searchResponses);
    //   });
    // },
    // beforeSearch: props.beforeSearch
}
})}
import { createSlice } from '@reduxjs/toolkit'

export const drawerSlice = createSlice({
  name: 'drawer',
  initialState: {
    left: false,
    right: false,
    embed: null
  },
  reducers: {
    toggleLeft: (state) => {
      state.left = !state.left;
    },
    toggleRight: (state) => {
      state.right = !state.right;
    },
    setEmbed: (state, newValue) => {
        state.embed = newValue.payload;
      },
  },
})

// Action creators are generated for each case reducer function
export const { toggleLeft, toggleRight,setEmbed } = drawerSlice.actions
export const { reducer } = drawerSlice;
import React from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import { useHits } from "react-instantsearch";
import dayjs from 'dayjs';
import { hitView } from "./MessageViews";
import { Divider } from '@mui/material';

const isPortrait = window.location.hash.indexOf('portrait') != -1;

export const ChatContainerInner = styled('div', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => {
        const { breakpoints } = theme;
        let starti = 120;
        if (open.embed) {
            starti += (Math.min(480, window.outerWidth) / (16.0 / 9.0));
        }
        let v = 'vh';
        let ex = '';
        if (isPortrait) {
          v = 'vw';
          ex = ' + 21%';
        }
        return {
            flexGrow: 1,
            height: `calc(100vh - ${Math.abs(starti)}px${ex})`,
            [`${breakpoints.up("xs")} and (orientation: landscape)`]: {
                height: `calc(100${v} - ${Math.abs(starti + 12)}px${ex})`,
            },
            [breakpoints.up("sm")]: {
                height: `calc(100${v} - ${Math.abs(starti + 28)}px${ex})`,
            },
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowY: 'scroll',
            display: 'flex',
            flexDirection: 'column-reverse',
            textOverflow: 'ellipsis',
            overflowX: 'clip',
            wordWrap: 'break-word',
            ...(open.left && !open.right && {
                transition: theme.transitions.create('margin', {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen,
                }),
                marginLeft: 0,
            }),
            ...(open.right && !open.left && {
                transition: theme.transitions.create('margin', {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen,
                }),
                marginRight: 0,
            }),
            ...(open.left && open.right && {
                transition: theme.transitions.create('margin', {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen,
                }),
            }),
        };
    }
);
import React from 'react';
import { useCurrentRefinements } from 'react-instantsearch';
import { styled } from '@mui/material/styles';
import { Chip, Paper } from '@mui/material';

const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
  }));

const CurrentRefinements = (props) => {
  const { items, refine } = useCurrentRefinements(props);
  
  return (
    <>
      {items.map((item) => (
          item.refinements.map((refinement) => {
            var label = refinement.label;
            if (refinement.label == "true" || refinement.label == "false") {
                label = item.label
            }
            if (refinement.label.split("|").length == 2) {
                label = refinement.label.split("|")[0];
            }
            return (
            <ListItem key={refinement.label}>
            <Chip
              label={label}
              onDelete={() => refine(refinement)}
            />
          </ListItem>
          )
        })
      ))}
    </>
  );
}

function isModifierClick(event) {
  const isMiddleClick = event.button === 1;

  return Boolean(
    isMiddleClick ||
      event.altKey ||
      event.ctrlKey ||
      event.metaKey ||
      event.shiftKey
  );
}

export default CurrentRefinements;
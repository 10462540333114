import { useSelector, useDispatch } from 'react-redux'
import { toggleLeft, toggleRight, setEmbed} from '../../state/UIState.js';
import { SearchBox } from "../Search";
import { IconButton, Toolbar as MUIToolbar } from '@mui/material';
import { Person, FilterAlt, StopCircle } from '@mui/icons-material';


const Toolbar = (props) => {
    const drawerState = useSelector((state) => state.drawer);
    const dispatch = useDispatch()

    return (
        <MUIToolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => dispatch(toggleLeft()) }
            edge="start"
            sx={{ mr: 2, ...(drawerState.left && { display: 'none' }) }}
          >
            <FilterAlt />
          </IconButton>
          <SearchBox
              queryHook={(query, search) => {
                search(query);
              }}
            />
            { drawerState.embed &&
            <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => dispatch(setEmbed(null)) }
            edge="end"
            sx={{ ml: 2 }}
          >
            <StopCircle />
          </IconButton>
            }
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => dispatch(toggleRight()) }
            edge="end"
            sx={{ ml: 2, ...(drawerState.right && { display: 'none' }) }}
          >
            <Person />
          </IconButton>
        </MUIToolbar>
    )
}

export default Toolbar;
import { createSlice, createAsyncThunk, buildCreateSlice } from '@reduxjs/toolkit'
import { createTheme } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux'
// var { Client } = require("@opensearch-project/opensearch");
// const ELASTIC_PASSWORD="y4kr.XiCwD7H7_X"
// const ELASTIC_USER="tim"
// const ELASTIC_URL="https://search-chat-454f6s74t4xgkpkul5dtw2aioa.us-east-1.es.amazonaws.com"

// const es = new Client({
//     node: ELASTIC_URL,
//     auth: {
//         username: ELASTIC_USER,
//         password: ELASTIC_PASSWORD
//     }
// });
async function fetchActiveUsersInChannels() {
    const url = 'https://xdding-cors-51c39b1137be.herokuapp.com/https://search-chat-454f6s74t4xgkpkul5dtw2aioa.us-east-1.es.amazonaws.com/chatlogs-alias/_search';
    const body = {
        size: 0,
        query: {
            bool: {
                should: [
                    { term: { "type.keyword": "join" } },
                    { term: { "type.keyword": "part" } }
                ],
                minimum_should_match: 1
            }
        },
        aggs: {
            by_channel: {
                terms: {
                    field: "channel.keyword",
                    size: 10
                },
                aggs: {
                    by_user: {
                        terms: {
                            field: "usernameNormalized.keyword",
                            size: 10
                        },
                        aggs: {
                            latest_join: {
                                max: {
                                    field: "timestamp",
                                    script: {
                                        source: "doc['type.keyword'].value == 'join' ? doc['timestamp'].value.toInstant().toEpochMilli() : 0"
                                    }
                                }
                            },
                            latest_part: {
                                max: {
                                    field: "timestamp",
                                    script: {
                                        source: "doc['type.keyword'].value == 'part' ? doc['timestamp'].value.toInstant().toEpochMilli() : 0"
                                    }
                                }
                            },
                            currently_in_channel: {
                                bucket_selector: {
                                    buckets_path: {
                                        joinTime: "latest_join",
                                        partTime: "latest_part"
                                    },
                                    script: "params.joinTime > params.partTime"
                                }
                            }
                        }
                    }
                }
            }
        }
    };

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Basic dGltOnk0a3IuWGlDd0Q3SDdfWA=='
            },
            body: JSON.stringify(body)
        });
        console.log(response);
        const data = await response.json();
        console.log(data); // Logging the response data for debugging; handle as needed
        return data;//.aggregations.by_channel.buckets;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}
let channelData = require('../utils/channel_data.json');
const streamerList = Object.keys(channelData);

export const updateOnlineUsersList = createAsyncThunk(
    'content/fetchContent',
    async () => {
        // return { data: [] }
            // const curChans = [...new Set(hits.map((hit) => hit.channel))];
            const url = 'https://xdding-cors-51c39b1137be.herokuapp.com/https://search-chat-454f6s74t4xgkpkul5dtw2aioa.us-east-1.es.amazonaws.com/chatlogs-alias/_search';
            const body = {
                size: 0,
                query: {
                    bool: {
                        should: [
                            { term: { "type.keyword": "join" } },
                            { term: { "type.keyword": "part" } }
                        ],
                        minimum_should_match: 1
                    }
                },
                aggs: {
                    by_channel: {
                        terms: {
                            field: "channel.keyword",
                            size: 10
                        },
                        aggs: {
                            by_user: {
                                terms: {
                                    field: "usernameNormalized.keyword",
                                    size: 10
                                },
                                aggs: {
                                    latest_join: {
                                        max: {
                                            field: "timestamp",
                                            script: {
                                                source: "doc['type.keyword'].value == 'join' ? doc['timestamp'].value.toInstant().toEpochMilli() : 0"
                                            }
                                        }
                                    },
                                    latest_part: {
                                        max: {
                                            field: "timestamp",
                                            script: {
                                                source: "doc['type.keyword'].value == 'part' ? doc['timestamp'].value.toInstant().toEpochMilli() : 0"
                                            }
                                        }
                                    },
                                    currently_in_channel: {
                                        bucket_selector: {
                                            buckets_path: {
                                                joinTime: "latest_join",
                                                partTime: "latest_part"
                                            },
                                            script: "params.joinTime > params.partTime"
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            };
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic dGltOnk0a3IuWGlDd0Q3SDdfWA=='
                },
                body: JSON.stringify(body)
            });
            const res = await response.json();
        const t = res.aggregations.by_channel.buckets.map((channel) => {
            return {channel: channel.key, users: channel.by_user.buckets.map((user) => {
                return {
                    'user': user.key,
                    'time': user.latest_join.value,
                    'color': user.color.buckets.length > 0 ? user.color.buckets[0].key : 'black'
                }
            })}
        });
        console.log(t);
        return { data: t}
    })

export const onlineUsersSlice = createSlice({
    name: 'onlineUsers',
    initialState: {
        data: []
    },
    reducers: {

    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(updateOnlineUsersList.fulfilled, (state, action) => {
          // Add user to the state array
          state.data = action.payload.data;
        })
      },
})

// Action creators are generated for each case reducer function
export const { reducer } = onlineUsersSlice;
// src/App.tsx
import React, { useEffect, useRef, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { Box, Divider, Grid, TablePagination, Typography, CssBaseline } from "@mui/material";
import Searchkit from "searchkit";
import Client from "@searchkit/instantsearch-client";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { drawerWidth } from './components/AppBar/common.js';

let channelData = require('./utils/channel_data.json');
const streamerList = Object.keys(channelData);

import {
  InstantSearch,
  useInstantSearch,
  useInfiniteHits,
  Stats,
  // CurrentRefinements
} from "react-instantsearch";


import { RefinementList, ToggleRefinement } from "./components/Filters";
import { SearchBox } from "./components/Search";
import { Refresher } from "./components/Refresher";
import { Range } from "./components/Range";
import { Hits } from "./components/Hits/Hits";
import { Pagination } from "./components/Pagination";
import { CurrentRefinements } from "./components/CurrentRefinements"
import { getSearchkitClient } from "./services/searchkit";
import { useSelector, useDispatch } from 'react-redux'

import { AppBarDrawer } from "./components/AppBarr"

const Thjing = styled('div', {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  marginTop: open.embed ? (Math.min(480,window.innerWidth)/(16.0/9.0) -16)+"px" : "0",
  transition: theme.transitions.create(['margin', 'height'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  })
}));
const Embed = styled('div', {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  position: 'absolute',
  textAlign: 'center',
  top: 0,
  left: 0,
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open.left && !open.right && {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: {sm:`${drawerWidth}px`},
    },
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  ...(!open.left && open.right && {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginRight: `${drawerWidth}px`,
    },
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  ...(open.left && open.right && {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px - ${drawerWidth}px)`,
      marginLeft: `${drawerWidth}px`,
      marginRight: `${drawerWidth}px`,
    },
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  ...(!open.left && !open.right && {
    width: `calc(100%)`,
    marginLeft: `0px`,
    marginRight: `0px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});
const App = () => {
  let twitchRef = useRef(); 
  const drawerState = useSelector((state) => state.drawer);

  const sbs = useSelector((state) => state.searchBefore.data);

    useEffect(() => {
    if (twitchRef.current) {
      if (drawerState.embed) {
        twitchRef.current.innerHTML = "";
        let w = Math.min(480,window.innerWidth);
      console.log(new Twitch.Embed(twitchRef.current, {
        width: w,
        layout: "video",
        height: (w/(16.0/9.0)),
        channel: drawerState.embed,
        // Only needed if this page is going to be embedded on other websites
      }));
    }
    else {
      twitchRef.current.innerHTML = "";
    }
    // setInit(true);
  }
  },[twitchRef, drawerState.embed]);
  
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Embed ref={twitchRef} open={drawerState}/>

      <InstantSearch 
        indexName="chatlogs-alias" 
        searchClient={getSearchkitClient({
          beforeSearch: async (searchRequests) => {
            console.log(sbs)

            return new Promise((resolve, reject) => {
              // console.log( searchRequests)
              // searchRequests[0].body.search_after=[1713484800000]
              // searchRequests[0].body.search_after=[1713484800000]
              if (sbs) {
                searchRequests[1].body.search_after=[sbs,0]
                searchRequests[0].body.search_after=[sbs,0]
                if (!searchRequests[0].body.from) searchRequests[0].body.from = 0;
                // searchRequests[1].body.sort["timestamp"] = "asc";
                searchRequests[1].body.sort = [
                  { "_id": { "order": "desc" }},
                { "timestamp": {"order": "desc"}}
                ]
                searchRequests[0].body.sort = [
                  { "_id": { "order": "desc" }},
                { "timestamp": {"order": "desc"}}
                ]
              }
              // searchRequests[1].body.sort["timestamp"] = "desc";
              // searchRequests[0].body.from = 0;
              // searchRequests.forEach((request) => {
              //   console.log(request)
                
              // })
              resolve(searchRequests)
      
            });
        //     return searchRequests.map((sr) => {
        //       if (sr.body?.sort) {
      
        //       }
        //       // if (sr.body?.sort) sr.body.sort = [{"timestamp": {"order": "asc", "format": "strict_date_optional_time_nanos"}}]
        //       return sr;
        //       // sr.body.search_after = [window.scrollerid];
        //  })
        // window.scrollerid=1713498115000;
            if (false) {
              return searchRequests.map((sr) => {
                if (sr.body) {
                  sr.body.from = 0;
                  sr.body.search_after = [window.scrollerid];
                }
                
                return sr;
             })
            }
            return searchRequests;
      
          }
        })}
        initialUiState={{}}
        onStateChange={({ uiState, setUiState }) => {
          localStorage.setItem('uiState', JSON.stringify(uiState))
          setUiState(uiState);
        }}
      >
        <Thjing open={drawerState}>
          <AppBarDrawer 
          />
          </Thjing>
      </InstantSearch>
    </ThemeProvider>
  );
};

export default App;

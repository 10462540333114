
import { updateStreamerList } from '../../state/StreamerListState.js';
import { useSelector, useDispatch } from 'react-redux'
import { TextField, FormControlLabel, Checkbox, Button} from '@mui/material';
import {
    useRefinementList
  } from "react-instantsearch";
import React, { useEffect, useRef, useMemo} from 'react';
import { MovingComponent } from "react-moving-text"
import { Lens } from '@mui/icons-material';



const RefinementList = (props) => {
    const {
      items,
      refine,
      searchForItems,
      toggleShowMore,
      canToggleShowMore
    } = useRefinementList(props);
    const streamerListState = useSelector((state) => state.streamerList.data);
    const dispatch = useDispatch();
  
    const { useTimer } = props;
  
    const [curStreamerData, setCurStreamerData] = React.useState(null);
    useEffect(() => {
      if (props.liveIndicator) {
          dispatch(updateStreamerList());
          const intervalId = setInterval(() => {
              dispatch(updateStreamerList());
          }, 10000); // Update every 60 seconds, adjust as needed

          // Cleanup interval on component unmount
          return () => clearInterval(intervalId);
      }
  }, [props.liveIndicator, dispatch]);
  
  return (
    <>
      {props.allowSearch &&
        <TextField
          size="small"
          fullWidth
          label="Search for User"
          variant="outlined"
          onChange={(event) => {
            searchForItems(event.target.value);
          }}
        />
      }
      {items.map((item) => {
        let extra = null;
        if (props.liveIndicator && streamerListState.find((s) => s._source.user_login == item.label.replace('#', ''))) {
          const sdata = streamerListState[item.label.replace('#', '')];
          extra = (
            <span>
              <MovingComponent
                type="flash"
                duration="8000ms"
                delay="2s"
                direction="normal"
                timing="linear"
                iteration="infinite"
                fillMode="none">
                <Lens fontSize="inherit" />
  
              </MovingComponent>
            </span>
          )
        }
        return (
          <div key={item.label}>
            <FormControlLabel
              label={
                <span>
                  {extra ? extra : null}
                  { props.isImage && 
                  <>
                    <img class="message-emote" src={"https://static-cdn.jtvnw.net/emoticons/v2/"+item.label+"/default/dark/3.0"} />
                </>
                  }
                  { !props.isImage && 
                    <span>{item.label}</span>
                  }
                  
                  <span className="item-count">{" (" + item.count + ")"}</span>
                </span>
              }
              control={
                <Checkbox
                  style={{
                    paddingBottom: 0,
                    paddingTop: 0,
                  }}
                  checked={item.isRefined}
                  onChange={(event) => {
                    refine(item.value);
                  }}
                />
              }
            />
          </div>
        )
      })}
      { props.showMore && 
        <Button
            disabled={!canToggleShowMore}
          onClick={() => {
            toggleShowMore();
          }}
        >
          Show More
        </Button>
      }
    </>
  );
  }

  export default RefinementList;
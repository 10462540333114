import { Drawer, SwipeableDrawer, Typography, IconButton, Divider } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux'
import { toggleRight } from '../../state/UIState.js';
import { DrawerHeader, drawerWidth } from './common.js';
import OnlineUsers from '../OnlineUsers';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import theme from '../../services/theme.js';
import { useEffect, useState } from 'react';

const InnerDrawer = (props) => {
  const dispatch = useDispatch();

  return (
     <>
    <DrawerHeader>

          <IconButton onClick={() => dispatch(toggleRight())}>
            {theme.direction === 'ltr' ? <ChevronRight /> : <ChevronLeft />}
          </IconButton>
          <Typography variant="h6" noWrap sx={{ flexGrow: 1 }} component="div">
            Users
          </Typography>
        </DrawerHeader>
        <Divider />
        <div className="right-drawer gha">
            <OnlineUsers />
        </div>
        </>
  )
}

const DrawerRight = (props) => {
    const drawerState = useSelector((state) => state.drawer);

    const dispatch = useDispatch();
    const [width, setWidth] = useState(window.innerWidth);
    
    function handleWindowSizeChange() {
      setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    const isMobile =  width <= 768;

    return (
      <>
      <SwipeableDrawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          display: { xs: drawerState.right ? 'block' : 'none', sm: 'block' },
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant={ isMobile ? "temporary" : "persistent" }
        anchor="right"
        open={drawerState.right}
        onOpen={() => dispatch(toggleRight())}
        onClose={() => dispatch(toggleRight())}
      >
        <InnerDrawer />
      </SwipeableDrawer>
      </>
    )
}

export default DrawerRight;
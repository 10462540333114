import { Drawer, SwipeableDrawer, Stack, Typography, IconButton, Divider } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux'
import { toggleLeft, toggleRight } from '../../state/UIState.js';
import { DrawerHeader, drawerWidth } from './common.js';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { RefinementList, ToggleRefinement, Range} from "../Refinements";
import CurrentRefinements from "../CurrentRefinements.jsx"
import theme from '../../services/theme.js';
import { useEffect, useState } from 'react';

const InnerDrawer = () => {
    const dispatch = useDispatch();

    return (
        <>
            <DrawerHeader>
                <Typography variant="h6" noWrap component="div">
                    Filters
                </Typography>
                <IconButton onClick={() => {
                    dispatch(toggleLeft())
                }}>
                    {theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}
                </IconButton>
            </DrawerHeader>
            <Divider />
            <div className="left-drawer gha">
                <Typography variant="overline" gutterBottom>
                    Channel
                </Typography>
                <RefinementList
                    allowSearch={false}
                    attribute="channel"
                    label="Channel"
                    limit={20}
                    liveIndicator={true}
                    showMore={true}
                    showMoreLimit={70}
                />
                <Divider className="filter-div" />
                <Typography variant="overline" gutterBottom>
                    User
                </Typography>
                <RefinementList
                    allowSearch={true}
                    attribute="username"
                    limit={20}
                    searchable={true}
                />
                <Divider className="filter-div" />
                <Typography variant="overline" gutterBottom>
                    Mentions
                </Typography>
                <RefinementList
                    allowSearch={true}
                    attribute="mentions"
                    limit={20}
                    searchable={true}
                />
                <Divider className="filter-div" />
                {/* <Typography variant="overline" gutterBottom>
                    Emotes
                </Typography>
                <RefinementList
                    attribute="emotes"
                    limit={15}
                    isImage={true}
                    showMore={true}
                    showMoreLimit={100}
                /> */}
                <Divider className="filter-div" />
                <Typography variant="overline" gutterBottom>
                    Date Range
                </Typography>
                <Range
                    attribute="timestamp"
                    label="Date"
                />
                <Divider className="filter-div" />
                <Typography variant="overline" gutterBottom>
                    Type
                </Typography>
                <RefinementList
                    allowSearch={false}
                    attribute="type"
                    label="Type"
                />
                <Divider className="filter-div" />
                <Typography variant="overline" gutterBottom>
                    Toggles
                </Typography>
                <ToggleRefinement
                    attribute="showJoinParts"
                    label="Show Join/Parts"
                    off={false}
                />
                <ToggleRefinement
                    attribute="modsOnly"
                    label="Mods Only"
                    off={false}
                />
                <ToggleRefinement
                    attribute="sarahMode"
                    label="Sarah Toggle"
                    off={false}
                />
                <Divider />
                <CurrentRefinements />
                </div>
        </>
    )
}

const LeftDrawer = (props) => {
    const drawerState = useSelector((state) => state.drawer);
    const dispatch = useDispatch();
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    const isMobile =  width <= 768;

    return (
        <>
        <SwipeableDrawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          display: { xs: drawerState.left ? 'block' : 'none', sm: 'block' },
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
            anchor={"left"}
            variant={ isMobile ? "temporary" : "persistent" }
            open={drawerState.left}
            onOpen={() => {
                dispatch(toggleLeft())
            }}
            onClose={() => {
                dispatch(toggleLeft())
            }}
        >
            <InnerDrawer />
        </SwipeableDrawer>
        </>
    )
}
export default LeftDrawer;
import { createSlice, createSelector } from '@reduxjs/toolkit'
import React, { useEffect, createRef } from 'react';


export const hitsSlice = createSlice({
    name: 'hits',
    initialState: {
        hits: [],
        topRef: createRef(),
        bottomRef: createRef()
    },
    reducers: {
        setHits: (state, hits) => {
            state.hits = hits;
        },
        setTopRef: (state, topRef) => {
            console.log(topRef);
            state.topRef = topRef.payload;
        },
        setBottomRef: (state, bottomRef) => {
            state.bottomRef = bottomRef;
        }
    },
    selectors: {
        scrollTop: createSelector(
            (sliceState) => sliceState.topRef,
            (value) => {
                if (value) {
                    value.current.scrollIntoView({ block: "start" });
                }
            }
        ),
        scrollBottom: createSelector(
            (sliceState) => sliceState.bottomRef,
            (value) => {
                if (value) {
                    value.current.scrollIntoView({ block: "start" });
                }
            }
        ),
        getUserColors: createSelector(
            (sliceState) => sliceState.hits,
            (value) => {
                let userColors = {};
                value.forEach((hit) => {
                    if (hit.userState?.color) {
                        userColors[hit.username] = hit.userState.color;
                    }
                });
                return userColors;
            }
          ),
    },
})

// Action creators are generated for each case reducer function
export const { setHits, setBottomRef, setTopRef } = hitsSlice.actions;
export const { getUserColors,  scrollTop, scrollBottom} = hitsSlice.selectors;
export const { reducer } = hitsSlice;
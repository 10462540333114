let badgeList = require('./badges.json');
let channelData = require('./channel_data.json');
let globalData = require('./global_data.json');

const globalSubs = globalData.data.find(d => d.set_id == 'subscriber')
const globalBits = globalData.data.find(d => d.set_id == 'bits')

export function parseBadges(badges,channel) {
    const chandata = channelData[channel.slice(1)];
    const subdata = chandata.data.find(d => d.set_id == 'subscriber')
    const bitsdata = chandata.data.find(d => d.set_id == 'bits')
    return badges.split(',').map(badgeStr => {
        var badgeSpl = badgeStr.split('/');
        var badge = badgeSpl[0];
        var sd = chandata.data.find((s) => s.set_id==badge)
        if (sd) {
            var mat = sd.versions.find(d => d.id == badgeSpl[1])
            if (mat) {
                mat.imageURL = mat.image_url_4x;
                return mat;
            }
        }
        else {
        }
        sd = globalData.data.find((s) => s.set_id==badge)
        if (sd) {
            var mat = sd.versions.find(d => parseInt(d.id) == parseInt(badgeSpl[1]))
            if (mat) {
                mat.imageURL = mat.image_url_4x;
                return mat;
            }
        }
        else {
            console.log(badge)
        }
        return null;
        if (badge == 'subscriber') {
        if (parseInt(badgeSpl[1]) != 0) {

            if (subdata) {
            const mat = subdata.versions.find(d => d.id == badgeSpl[1])
            if (mat) {
                mat.imageURL = mat.image_url_4x;
                return mat;
            }
        }
            const mat = globalSubs.versions.find(d => d.id == badgeSpl[1])
            if (mat) {
                mat.imageURL = mat.image_url_4x;
                return mat;
            }
        }
    }
        else if (badge == 'bits') {
            if (bitsdata) {
            const mat = bitsdata.versions.find(d => d.id == badgeSpl[1])
            if (mat) {
                mat.imageURL = mat.image_url_4x;
                return mat;
            }
        }
            const mat = globalBits.versions.find(d => d.id == badgeSpl[1])
            if (mat) {
                mat.imageURL = mat.image_url_4x;
                return mat;
        }
        }
        const match = badgeList.badges.find(badgeListItem => badgeListItem.setID == badge);
        if (match) {
            return match
        }
    })
}